<template>

<com-content class="form-wrapper" :class="{'is-loading': loading}">

	<com-content class="form form-personal">

		<com-content :inner="true">

			<h2>About you</h2>

			<app-input-text v-model="model.user.firstname" label="First name" placeholder="Enter your first name" :validation="$v.model.user.firstname" />
			<app-input-text v-model="model.user.lastname" label="Last name" placeholder="Enter your last name" :validation="$v.model.user.lastname" />
			<app-input-account v-model="model.user.email" label="E-mail" :text="emailMessages" placeholder="Enter your e-mail address" :validation="$v.model.user.email" />

		</com-content>

	</com-content>

	<com-content class="form form-convention" :disabled="!validUser">

		<com-content :inner="true">

			<h2>About your convention</h2>

			<app-input-text v-model="model.convention.name" label="Name" placeholder="Enter the convention name" />
			<app-input-date v-model="model.convention.from" label="Starts" placeholder="Enter a start date" />
			<app-input-date v-model="model.convention.to" label="Ends" placeholder="Enter an end date" />
			<app-input-toggle v-model="model.convention.currency" label="Currency" :options="currencyOptions" />
			<app-input-text v-model="model.convention.website" label="Website" placeholder="Enter website (optional)" />
			<app-input-text v-model="model.convention.attendance" label="Estimated Attendance" placeholder="Enter estimate" />
			<app-input-text v-model="model.convention.info" :autogrow="true" label="Additional Info" placeholder="Enter any other details you feel are relevant..." />

		</com-content>

	</com-content>

	<com-content class="form form-personal" :disabled="!validConvention">

		<com-content :inner="true">

			<h2>Which services do you need?</h2>

			<div class="form-platform">

				<app-input-toggle v-model="model.platform.registration" label="Registration" />
				<app-input-toggle v-model="model.platform.schedule" label="Schedule" />
				<app-input-toggle v-model="model.platform.checkout" label="Checkout" />
				<app-input-toggle v-model="model.platform.library" label="Library" />

			</div>

		</com-content>

	</com-content>

	<com-content :blue="true" class="form form-submit">

		<com-content :inner="true">

			<app-button text="Confirm" :disabled="!validPlatform" theme="yellow" v-on:click="onSubmitClick" :loading="loading" />

		</com-content>

	</com-content>

</com-content>
	
</template>

<script>

import { validationMixin } from 'vuelidate'
import { email, required } from 'vuelidate/lib/validators'

export default {

	mixins: [validationMixin],

	components: {

		'com-content': () => import('./../common/Content')

	},

	data: function() {

		return {
			currencyOptions: {
				1: 'USD',
				2: 'CAD'
			},
			loading: false,
			emailMessages: {
				info: 'If you already have a Cardboard Events account then you can use the same e-mail address here to link the convention to your account.',
				exists: 'This e-mail address is already associated with an existing Cardboard Events account. The convention will be linked to it.',
				register: 'A Cardboard Events login will be generated for this e-mail address. The login credentials will automatically be e-mailed to you.'
			},
			model: {
				user: {
					firstname: '',
					lastname: '',
					email: '',
				},
				convention: {
					currency: 1,
					name: '',
					from: '',
					to: '',
					website: '',
					info: '',
					attendance: ''
				},
				platform: {
					registration: 0,
					schedule: 0,
					checkout: 0,
					library: 0
				}
			}
		}

	},

	validations: {
		model: {
			user: {
				firstname: {
					required
				},
				lastname: {
					required
				},
				email: {
					required,
					email
				}
			},
			convention: {
				name: {
					required
				},
				from: {
					required
				},
				to: {
					required
				}
			},
		}
	},

	computed: {

		validUser: function() {

			return !this.$v.model.user.email.$invalid && !this.$v.model.user.firstname.$invalid && !this.$v.model.user.lastname.$invalid

		},

		validConvention: function() {

			return this.validUser && !this.$v.model.convention.name.$invalid && !this.$v.model.convention.to.$invalid && !this.$v.model.convention.from.$invalid && this.model.convention.to > this.model.convention.from && this.model.convention.from > this.$time

		},

		validPlatform: function() {

			return this.validUser && this.validConvention && (this.model.platform.registration || this.model.platform.schedule || this.model.platform.checkout)

		}

	},

	methods: {

		onSubmitClick: function() {

			this.loading = true
			
			this.$api.post('signup', this.model).then(function() {

				this.$router.push({
					name: 'Success'
				})

			}.bind(this), function() {

				this.$notify({
					message: ['Sorry, your convention could not created. Please try again.'],
					type: 'error'
				})

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.form-wrapper.is-loading {
	pointer-events: none;
}

.form {
	padding: 40px 0px;
	width: 100%;
}

.is-mobile .form {
	padding: 40px 10px 20px 10px;
}

.form >>> .input {
	width: 100%!important;
}

.form-personal {
	z-index: 2;
}

.form-personal:after {
	content: '';
	display: block;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 24px 32px 0 32px;
	border-color: #fff transparent transparent transparent;
	position: absolute;
	bottom: -24px;
	left: 50%;
	margin-left: -32px;
}

.form-personal.is-disabled:after {
	display: none;
}

.form-convention {
	background-color: #eee;
}

.form-convention:after {
	content: '';
	display: block;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 24px 32px 0 32px;
	border-color: #eee transparent transparent transparent;
	position: absolute;
	bottom: -24px;
	left: 50%;
	margin-left: -32px;
}

.form h2 {
	font-size: 28px;
    font-weight: 500;
    text-align: center;
	margin-bottom: 40px;
    color: #444;
}

.is-mobile .form h2 {
	font-size: 20px;
	margin-bottom: 20px;
}

.form-platform {
	width: 100%;
	display: grid;
	grid-gap: 10px;
	grid-template-columns: 1fr 1fr;
}

.is-mobile .form-platform {
	grid-template-columns: 1fr;
}

.form-submit p {
	font-size: 16px;
	line-height: 20px;
	font-weight: 400;
	color: #fff;
	margin-bottom: 20px;
	text-align: center;
}

.form-submit >>> .button {
	color: #333;
}

</style>